// function fadeIn() {
//     document.querySelector('body').classList.add('fade-in')
// }

document.querySelector('#toggle').addEventListener('click', (e) => {
    document.querySelector('.content').classList.toggle('blur');
})

document.querySelectorAll('a').forEach(element => {
    element.addEventListener('click', (e) => {
        let target = e.target;
        if (target.tagName !== 'A') {
            target = target.parentNode;
        }
        e.preventDefault();
        document.querySelector('body').classList.add('fade-out');
        setTimeout(function() {
                window.location.href = target.href;
            },
            850
        );
    })
})
